import * as React from "react";
import {Box, VStack, Heading, Text} from "@chakra-ui/react";

export function Card(props) {
    return (
        // <Center
        //     height={props.backgroundHeight || "100vh"}
        //     backgroundColor={props.backgroundColor || "white"}
        // >
            <Box
                backgroundColor="gray.100"
                padding={8}
                borderRadius="lg"
                width="100%"
            >
                <VStack
                    spacing={4}
                    align="center"
                >
                    <Heading
                        fontWeight="bold"
                        fontSize="3xl"
                    >
                        {props.title}
                    </Heading>
                    <Text
                        fontSize="lg"
                        color="gray.500"
                    >
                        {props.subtitle}
                    </Text>
                </VStack>
            </Box>
        // </Center>
    );
}