import { Loader } from "@googlemaps/js-api-loader";

export function loadSdk() {
    const loader = new Loader({
        apiKey: "AIzaSyD_k9Zbkxpmpmd12n5kuLGQbXrm2hpBpSE",
        version: "weekly",
    });

    loader.load().then(() => {
        console.log("loaded")
    });
}

export async function calcDistances(studentsCSV, busRoutesCSV) {
    const directionsService = new window.google.maps.DirectionsService();

    const studentPostCodes = studentsCSV.split("\n");
    const busRoutePostCodes = busRoutesCSV.split("\n");

    let output = await Promise.all(studentPostCodes.map(async (studentPostCode) => {
        let distances =  await Promise.all(busRoutePostCodes.map(async (busRoutePostCode) => {
            const distance = await callApi(studentPostCode, busRoutePostCode, directionsService);
            return {
                location: busRoutePostCode,
                distance: distance,
                student: studentPostCode
            };
        }));

        let best = distances[0];
        for (let i = 1; i < distances.length; i++) {
            if (distances[i].distance < best.distance) {
                best = distances[i];
            }
        }

        return best;
    }));
    output = output.filter(({ distance }) => distance >= 0);

    const strOutput = output
        .map(({ student, location, distance }) => student + "," + location + "," + distance)
        .join("\n");

    const absoluteError = output.reduce((acc, value) => acc + parseInt(value.distance), 0);
    const meanAbsoluteError = absoluteError / output.length;
    const worstCommute = output.sort((lhs, rhs) => rhs.distance - lhs.distance)[0].distance;

    const busRouteFrequency = { };
    output.forEach(({ location }) => busRouteFrequency[location] = 1 + (busRouteFrequency[location] ?? 0));

    const busRouteDistance = { };
    output.forEach(({ location, distance }) => busRouteDistance[location] = parseInt(distance) + (busRouteDistance[location] ?? 0));

    const strBusRouteFrequency = Object.keys(busRouteFrequency)
        .map(key => key + "," + busRouteFrequency[key] + "," + Math.round(busRouteDistance[key] / busRouteFrequency[key]))
        .join("\n");

    let result = {
        output: output,
        strOutput: strOutput,
        absoluteError: absoluteError,
        meanAbsoluteError: Math.round(meanAbsoluteError),
        busStopFrequency: busRouteFrequency,
        busStopDistance: busRouteDistance,
        strBusStopFrequency: strBusRouteFrequency,
        worstCommute: worstCommute
    }

    console.log(result);

    return result;
}

async function callApi(origin, destination, directionsService) {
    if (localStorage.getItem(origin + "|" + destination)) return localStorage.getItem(origin + "|" + destination);

    console.log("fetching");

    let originNormalised = origin;
    if (originNormalised.length < 10) originNormalised += ", London UK";

    let destinationNormalised = destination;
    if (destinationNormalised.length < 10) destinationNormalised += ", London UK";

    // const url = baseApiUrl + "origin=" + originNormalised + "&destination=" + destinationNormalised + "&key=" + apiKey + "&mode=WALKING";
    // const response = await fetch(url);
    // const responseJson = await response.json();

    const request = {
        origin: originNormalised,
        destination: destinationNormalised,
        provideRouteAlternatives: false,
        travelMode: "WALKING",
        unitSystem: window.google.maps.UnitSystem.METRIC
    };
    const response = await directionsService.route(request);

    if (!response.routes[0]) return -1;
    const distance = response.routes[0].legs[0].distance.value;

    localStorage.setItem(origin + "|" + destination, distance);

    return distance;
}