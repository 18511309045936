import * as React from "react";
import {Button} from "@chakra-ui/react";

export function DownloadButton(props) {
    return (
        <Button
            onClick={props.onClick}
            variant="link"
        >
            {props.title}
        </Button>
    )
}