import * as React from "react";
import {Box, Flex} from "@chakra-ui/react";

export function PageWrap(props) {
    const { bgColor, minHeight, ...rest } = props;

    return (
        <Box
            bgColor={bgColor}
            minHeight={minHeight}
            {...rest}
        >
            <Flex
                direction="column"
                align="center"
                marginLeft={["3%", "6%", "10%", "15%"]}
                marginRight={["3%", "6%", "10%", "15%"]}
            >
                {props.children}
            </Flex>
        </Box>
    )
}