import * as React from "react";
import {Text} from "@chakra-ui/react";

export function Description() {
    return (
        <Text
            fontSize="sm"
            lineHeight={1.75}
            color="gray.400"
            textAlign="center"
            width="100%"
            paddingTop={16}
        >
            Notes on use: Student locations and bus stops must be post codes or addresses (not containing commas). Input
            can be copy and pasted, directly typed or uploaded from a file. Output CSV is in the format student_postcode,bus_stop,distance
            and bus_stop,frequency,mean_distance.
        </Text>
    )
}