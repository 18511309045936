import {Textarea, VStack, Heading, Center, Text} from "@chakra-ui/react"
import {DownloadButton} from "./downloadButton";
import {download} from "../model/download";
import {useDropzone} from 'react-dropzone'
import {useCallback} from "react";

export default function TextEntry(props) {
    const { title, placeholder, value, setValue, readOnly, filename, subtitle } = props;

    const onDrop = useCallback(acceptedFiles => {
        console.log("callback")
        acceptedFiles[0].text()
            .then(setValue);
    }, [setValue]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: 'text/*',
        noClick: true,
        noKeyboard: true
    })

    const handleChange = ({ target }) => setValue(target.value);
    const handleDownload = () => download(filename, value);
    const startUpload = () => {
        const fileElem = document.getElementById("fileElem");
        if (fileElem) fileElem.click();
    }
    const handleUpload = ({ target }) => {
        target.files[0].text()
            .then(setValue);
    };

    let downloadButton = <DownloadButton onClick={handleDownload} title="Download" />;
    let uploadButton = (
        <>
            <input type="file" id="fileElem" accept="text/*" onChange={handleUpload} style={{display: "none"}} {...getInputProps()} />
            <DownloadButton onClick={startUpload} title="Upload" />
        </>
    );

    let textArea = <Textarea
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        resize="none"
        isReadOnly={readOnly ?? false}
        minHeight="35vh"
        focusBorderColor="teal.400"
    />;
    let dropBox = (
        <Center
            borderRadius="md"
            borderWidth="1px"
            minHeight="35vh"
            width="100%"
        >
            <Text
                fontSize="xl"
                fontWeight="bold"
            >
                Drop file here
            </Text>
        </Center>
    );

    return (
        <VStack
            width="100%"
            spacing={4}
            alignContent="left"
            {...getRootProps()}
        >
            <Heading
                fontSize="2xl"
                fontWeight="bold"
                textAlign="left"
            >
                {title}
            </Heading>
            {subtitle ?
                <Text
                    fontSize="sm"
                    color="gray.400"
                    textAlign="center"
                >
                    {subtitle}
                </Text>
                : null}
            {isDragActive ? dropBox : textArea}
            {filename ? downloadButton : uploadButton}
        </VStack>
    )
}