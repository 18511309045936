import * as React from "react";
import {Button, HStack, VStack} from "@chakra-ui/react";
import TextEntry from "./components/textEntry";
import {useEffect, useState} from "react";
import {Card} from "./components/card";
import {loadSdk, calcDistances} from "./model/api";
import {PageWrap} from "./components/pageWrap";
import {Description} from "./components/description";
import {Helmet} from "react-helmet";

function App() {
    let [studentText, setStudentText] = useState("");
    let [busRoute, setBusRoute] = useState("");
    let [output, setOutput] = useState(null);
    let [isLoading, setIsLoading] = useState(false);

    const run = () => {
        if (isLoading) return;

        setIsLoading(true);
        setOutput(null);

        calcDistances(studentText, busRoute)
            .then(output => {
                setOutput(output);
                setIsLoading(false);
            })
            .catch(output => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadSdk();
    }, []);

    let results = <></>;
    if (output) {
        results = (
            <VStack
                spacing={16}
                width="100%"
            >
                <HStack
                    spacing={8}
                    width="100%"
                >
                    <Card
                        title={output.meanAbsoluteError + " m"}
                        subtitle="Average distance"
                    />
                    <Card
                        title={output.worstCommute + " m"}
                        subtitle="Worst commute"
                    />
                </HStack>
                <HStack
                    spacing={8}
                    width="100%"
                >
                    <TextEntry
                        title="Students"
                        subtitle="student_postcode,bus_stop,distance"
                        value={output.strOutput}
                        setValue={() => {
                        }}
                        placeholder=""
                        readOnly={true}
                        filename="nearest_bus_stops.csv"
                    />
                    <TextEntry
                        title="Bus Stops"
                        subtitle="bus_stop,frequency,mean_distance"
                        value={output.strBusStopFrequency}
                        setValue={() => {
                        }}
                        placeholder=""
                        readOnly={true}
                        filename="bust_stop_frequency.csv"
                    />
                </HStack>
            </VStack>
        );
    }

    return (
        <>
            <Helmet>
                <title>Bus Routes</title>
                <meta name="description"
                      content="A simple React app using the Google Maps JavaScript API to calculate distances to bus stops for students."/>
            </Helmet>
            <PageWrap>
                <VStack
                    spacing={16}
                    alignContent="center"
                    width="100%"
                    minHeight="100vh"
                    marginTop={32}
                    marginBottom={32}
                >
                    <HStack
                        spacing={8}
                        width="100%"
                    >
                        <TextEntry
                            title="Students"
                            value={studentText}
                            setValue={setStudentText}
                            placeholder="Student postcodes or addresses, separated by new-lines"
                        />
                        <TextEntry
                            title="Bus Stops"
                            value={busRoute}
                            setValue={setBusRoute}
                            placeholder="Bus stop postcodes or addresses, separated by new-lines"
                        />
                    </HStack>
                    <Button
                        colorScheme="teal"
                        size="lg"
                        onClick={run}
                        isDisabled={studentText === "" || busRoute === ""}
                    >
                        Run distance analysis
                    </Button>
                    {results}
                    <Description/>
                </VStack>
            </PageWrap>
        </>
    );
}

export default App;
